import React from 'react'
import { classes, media, stylesheet } from 'typestyle'
import { em } from 'csx'
import { Link } from 'react-router-dom'
import { usePortfolioSectionsQuery } from '../../graphql/usePortfolioSections'
import Loading from '../../components/Loading'
import { Theme } from 'vo-components'

const styles = stylesheet({
    leftPane: {
        paddingTop: em(2),
        textAlign: 'left',
        ...media(Theme.screen.phone, {
            textAlign: 'center',
        }),
    },
    navContainer: {
        marginTop: em(2),
        display: 'flex',
        flexDirection: 'column',
    },
    navItem: {
        marginBottom: em(0.5),
    },
    navLink: {
        color: 'initial',
    },
    socialIconContainer: {
        marginTop: em(2),
    },
    socialIcon: {
        justifyContent: 'flex-start',
    },
})

export default function LandingSectionLinks() {
    const { loading, data } = usePortfolioSectionsQuery()
    const portfolioSections = data?.listPortfolioSections?.items || []
    return (
        <div className={styles.leftPane}>
            <p className="is-family-secondary is-italic">What I love to capture</p>
            <div className={styles.navContainer}>
                {loading && (
                    <>
                        {Array(5)
                            .fill(null)
                            .map((_, index) => (
                                <div key={`loading-bar-home-links-${index}`} className={styles.navItem}>
                                    <Loading>
                                        <span>Loading</span>
                                    </Loading>
                                </div>
                            ))}
                    </>
                )}
                {portfolioSections.map(section => (
                    <span
                        className={classes('has-text-weight-bold is-uppercase has-letter-spacing', styles.navItem)}
                        key={section.portfolio_section_key}
                    >
                        <Link to={`/portfolio/${section.slug}`} className={styles.navLink}>
                            {section.name}
                        </Link>
                    </span>
                ))}
                {!loading && (
                    <span className={classes('has-text-weight-bold is-uppercase has-letter-spacing', styles.navItem)}>
                        <Link to={`/projects`} className={styles.navLink}>
                            Other exciting projects
                        </Link>
                    </span>
                )}
            </div>
            <div className={styles.socialIconContainer}>
                <span className={classes('icon', styles.socialIcon)}>
                    <a className="has-text-black" href="https://instagram.com/veeongg" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram" />
                    </a>
                </span>
                <span className={classes('icon', styles.socialIcon)}>
                    <a className="has-text-black" href="https://www.linkedin.com/in/veronicaonggoro/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin" />
                    </a>
                </span>
            </div>
        </div>
    )
}
