import React from 'react'
import Instagram from '../../components/Instagram'
import AboutMe from './AboutMe'
import Helmet from 'react-helmet'
import { GuestContactForm } from '../../components/ContactForm'

export default function PageContactMe() {
    return (
        <>
            <Helmet>
                <title>Contact Me | Veronica Onggoro</title>
                <meta name="description" content="Reach out to Veronica for any enquiries, feedback or questions." />
            </Helmet>
            <AboutMe />
            <GuestContactForm />
            <Instagram />
        </>
    )
}
