import React, { useMemo } from 'react'
import { classes, stylesheet } from 'typestyle'
import { url, em } from 'csx'
import Loading from './Loading'
import { useInstagramPostsQuery } from '../graphql/useInstagramPosts'

const styles = stylesheet({
    headingContainer: {
        marginBottom: em(2),
    },
    instagramPost: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
})

export default function Instagram() {
    const { data, loading } = useInstagramPostsQuery()
    const posts = useMemo(() => (data?.listInstagramPosts?.items || []).slice(0, 6), [data])
    return (
        <div className="section">
            <div className="container">
                <div className={styles.headingContainer}>
                    <p className="has-text-centered">
                        <a
                            className="is-family-secondary is-italic is-lowercase has-text-grey"
                            href="https://www.instagram.com/veeongg/"
                            target="__blank"
                        >
                            @veeongg
                        </a>
                    </p>
                    <h2 className="has-text-centered is-size-3-tablet is-size-4-mobile ">
                        <a
                            className="has-text-black has-text-weight-bold is-uppercase has-letter-spacing"
                            href="https://www.instagram.com/veeongg/"
                            target="__blank"
                        >
                            follow me on instagram
                        </a>
                    </h2>
                </div>
                <div className="columns">
                    {loading &&
                        Array(6)
                            .fill(null)
                            .map((_, index) => (
                                <div key={`loading-bar-instagram-${index}`} className="column is-2">
                                    <Loading>
                                        <figure className="image is-1by1">
                                            <div className="has-ratio" />
                                        </figure>
                                    </Loading>
                                </div>
                            ))}
                    {posts.map(
                        post =>
                            post.pictureUrl && (
                                <div key={post.id} className="column is-2">
                                    <a href={post.externalUrl || ''} target="__blank">
                                        <figure className="is-relative image is-1by1">
                                            <div
                                                className={classes(styles.instagramPost, 'has-ratio')}
                                                style={{ backgroundImage: url(post.pictureUrl) }}
                                            />
                                        </figure>
                                    </a>
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}
