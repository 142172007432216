import React from 'react'
import { classes, media, stylesheet } from 'typestyle'
import { em } from 'csx'
import { Theme, DynamicImage, ProfileAboutMe } from 'vo-components'
import { useProfileQuery } from '../../graphql/useProfile'
import Loading from '../../components/Loading'

const styles = stylesheet({
    columns: {
        ...media(Theme.screen.phone, {
            flexDirection: 'column-reverse',
        }),
    },
    headingContainer: {
        marginBottom: em(2),
    },
    image: {
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    mobileImageContainer: {
        marginTop: em(2),
    },
    loadingTextContainer: {
        marginBottom: em(0.5),
    },
    loadingTextSmall: {
        height: em(1),
    },
    loadingTextHeading: {
        height: em(2),
    },
})

const PAGE_KEY = 'ABOUT_ME_CONTACT'

function AboutMeLoading() {
    return (
        <div className="section is-medium">
            <div className="container">
                <div className={classes('columns is-vcentered is-mobile is-variable is-8-tablet', styles.columns)}>
                    <div className="column is-full-mobile is-6-tablet">
                        <Loading>
                            <figure className="image is-1by1">
                                <div className="has-ratio" />
                            </figure>
                        </Loading>
                    </div>
                    <div className="column is-full-mobile is-6-tablet">
                        <div className={styles.headingContainer}>
                            <div className={styles.loadingTextContainer}>
                                <Loading>
                                    <div className={styles.loadingTextSmall} />
                                </Loading>
                            </div>
                            <Loading>
                                <div className={styles.loadingTextHeading} />
                            </Loading>
                        </div>
                        <div className="content">
                            <>
                                {Array(5)
                                    .fill(null)
                                    .map((_, index) => (
                                        <div key={`loading-bar-about-me-${index}`} className={styles.loadingTextContainer}>
                                            <Loading>
                                                <div className={styles.loadingTextSmall} />
                                            </Loading>
                                        </div>
                                    ))}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function AboutMe() {
    const { loading, data } = useProfileQuery({
        pageKey: PAGE_KEY,
    })
    if (loading) return <AboutMeLoading />
    const profile = (data?.showProfile?.value || {}) as ProfileAboutMe
    const file = data?.showProfile?.file
    return (
        <div className="section is-medium">
            <div className="container">
                <div className={classes('columns is-vcentered is-mobile is-variable is-8-tablet', styles.columns)}>
                    {/* Visible except on mobile */}
                    <div className="column is-hidden-mobile is-full-mobile is-4-tablet">
                        <figure className="image is-4by5">
                            <DynamicImage src={file?.public_url || ''} width={720} />
                        </figure>
                    </div>
                    <div className="column is-full-mobile is-6-tablet">
                        <div className={styles.headingContainer}>
                            <p className="is-family-secondary is-italic is-lowercase">{profile.about_me_blurb}</p>
                            <h2 className="is-size-3-tablet is-size-4-mobile has-text-black has-text-weight-bold is-uppercase has-letter-spacing">
                                {profile.about_me_title}
                            </h2>
                            {/* Only for Mobile */}
                            <figure className={classes('image is-1by1 is-hidden-tablet', styles.mobileImageContainer)}>
                                <DynamicImage src={file?.public_url || ''} width={720} />
                            </figure>
                        </div>
                        <div className="content has-newline">{profile.about_me_content}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
