import React from 'react'
import { classes, stylesheet } from 'typestyle'
import { em } from 'csx'
import { Link } from 'react-router-dom'
import { Theme, DynamicImage, PortfolioSection } from 'vo-components'
import { usePortfolioSectionsQuery } from '../../graphql/usePortfolioSections'
import chunk from 'lodash.chunk'
import Loading from '../../components/Loading'
import Helmet from 'react-helmet'

const styles = stylesheet({
    title: {
        marginTop: em(1),
        marginBottom: em(1),
        color: Theme.colors.greyDark,
        $nest: {
            '&:hover': {
                color: Theme.colors.black,
            },
        },
    },
    sectionImage: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    loadingContainer: {
        marginBottom: em(0.5),
    },
})

const META_DESCRIPTION = "Explore some of Veronica's work which materialise her passion for photography and videography."

export default function PagePortfolioList() {
    const { loading, data } = usePortfolioSectionsQuery()
    const portfolioSectionChunks = chunk<PortfolioSection>(data?.listPortfolioSections?.items || [], 2)
    return (
        <>
            <Helmet>
                <title>Portfolio | Veronica Onggoro</title>
                <meta property="og:title" content="Portfolio List" />
                <meta property="og:description" content={META_DESCRIPTION} />
                <meta name="description" content={META_DESCRIPTION} />
            </Helmet>
            <div className="section">
                <div className="container">
                    {loading && (
                        <>
                            {Array(3)
                                .fill(null)
                                .map((_, index) => (
                                    <div key={`loading-bar-portfolio-section-${index}`} className="columns is-multiline">
                                        <div className="column is-4">
                                            <div className={styles.loadingContainer}>
                                                <Loading>
                                                    <figure className="image is-1by1">
                                                        <div className="has-ratio" />
                                                    </figure>
                                                </Loading>
                                            </div>
                                            <Loading>
                                                <h2>test</h2>
                                            </Loading>
                                        </div>
                                        <div className="column is-4">
                                            <div className={styles.loadingContainer}>
                                                <Loading>
                                                    <figure className="image is-1by1">
                                                        <div className="has-ratio" />
                                                    </figure>
                                                </Loading>
                                            </div>
                                            <Loading>
                                                <h2>test</h2>
                                            </Loading>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                    {portfolioSectionChunks.map((portfolioSections, index) => (
                        <div key={`portfolio-section-chunk-${index}`} className="columns is-multiline">
                            {portfolioSections.map(portfolioSection => (
                                <div key={portfolioSection.portfolio_section_key} className="column is-4">
                                    <Link to={`/portfolio/${portfolioSection.slug}`}>
                                        <figure className="image is-1by1">
                                            <DynamicImage
                                                src={portfolioSection.banner?.public_url || ''}
                                                width={1280}
                                                srcSet={[{ maxScreenWidth: 480, imageWidth: 1080 }]}
                                            />
                                        </figure>
                                    </Link>
                                    <Link to={`/portfolio/${portfolioSection.slug}`}>
                                        <h2 className={classes('is-size-5 is-uppercase has-letter-spacing has-text-weight-bold', styles.title)}>
                                            {portfolioSection.name}
                                        </h2>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
