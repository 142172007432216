import React, { useState, useEffect, useMemo } from 'react'
import { Collection, CollectionAssetType, getDynamicImageURL, calculateColumnOriginalIndex, useColumns, Lightbox } from 'vo-components'
import { useCollectionAssetsLazyQuery } from '../../graphql/useCollectionAssets'
import PhotoAsset from './PhotoAsset'
import { classes, stylesheet } from 'typestyle'
import { em } from 'csx'

const styles = stylesheet({
    photosContainer: {
        marginTop: em(4),
    },
})

interface PhotosProps {
    collection: Collection
}

export default function Photos({ collection }: PhotosProps) {
    const collectionAsset = collection.segments?.find(segment => segment.type === CollectionAssetType.Image)?.assets
    const [nextToken, setNextToken] = useState<string | undefined>(collectionAsset?.nextToken || undefined)
    const [loadMore, { called, loading, data }] = useCollectionAssetsLazyQuery()
    useEffect(() => {
        if (called) {
            setNextToken(data?.listCollectionAssets?.nextToken || undefined)
        }
    }, [called, data])

    const photos = useMemo(() => [...(collectionAsset?.items || []), ...(data?.listCollectionAssets?.items || [])].filter(photo => !!photo.file), [
        collectionAsset,
        data,
    ])
    const { chunkedItems: photoChunks, columnCount } = useColumns({ items: photos })
    return (
        <div className={styles.photosContainer}>
            <Lightbox
                images={photos
                    .map<string>(photo => getDynamicImageURL({ src: photo.file?.public_url || '', width: 1280 }))
                    .filter(url => !!url)}
            >
                <div className="columns is-multiline is-variable is-1-mobile is-3-desktop">
                    {photoChunks.map((photos, chunkIdx) => (
                        <div key={`chunk-${chunkIdx}`} className="column is-6-tablet is-4-desktop">
                            {photos.map((photo, photoIdx) => (
                                <PhotoAsset
                                    key={photo.asset_id}
                                    photo={photo}
                                    index={calculateColumnOriginalIndex(chunkIdx, photoIdx, columnCount)}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </Lightbox>
            {nextToken && (
                <div className="buttons is-centered">
                    <button
                        onClick={() =>
                            loadMore({
                                variables: {
                                    collection_id: collection.collection_id,
                                    type: CollectionAssetType.Image,
                                    next_token: nextToken,
                                },
                            })
                        }
                        className={classes('button is-outline', loading && 'is-loading')}
                    >
                        <span>Load more</span>
                    </button>
                </div>
            )}
        </div>
    )
}
