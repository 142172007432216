import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { LIST_COLLECTION_ASSETS, Maybe, CollectionAssetConnection, QueryListCollectionAssetsArgs, CollectionAssetType } from 'vo-components'

export function useCollectionAssetsLazyQuery() {
    return useLazyQuery<{ listCollectionAssets: Maybe<CollectionAssetConnection> }, QueryListCollectionAssetsArgs>(LIST_COLLECTION_ASSETS)
}

interface CollectionAssetsQuery {
    collection_id: string
    type: CollectionAssetType
    next_token?: string
    limit?: number
    notifyOnNetworkStatusChange?: boolean
}

export function useCollectionAssetsQuery({ collection_id, type, next_token, limit, notifyOnNetworkStatusChange }: CollectionAssetsQuery) {
    return useQuery<{ listCollectionAssets: Maybe<CollectionAssetConnection> }, QueryListCollectionAssetsArgs>(LIST_COLLECTION_ASSETS, {
        variables: { collection_id, type, next_token, limit },
        notifyOnNetworkStatusChange,
    })
}
