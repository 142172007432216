import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Header from './components/Header'
import PageHome from './pages-public/home/PageHome'
import PagePortfolioList from './pages-public/portfolio-list/PagePortfolioList'
import PageContactMe from './pages-public/contact-me/PageContactMe'
import { Auth, Storage } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { ApolloProvider } from '@apollo/react-hooks'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import Footer from './components/Footer'
import { stylesheet } from 'typestyle'
import { viewHeight } from 'csx'
import PageCollectionAssetList from './pages-public/collection-asset-list/PageCollectionAssetList'
import PageProjects from './pages-public/collection-list/PageProjects'
import PagePortfolioSectionCollections from './pages-public/collection-list/PagePortfolioSectionCollections'
import { Modal } from 'vo-components'
import ScrollToTop from './components/ScrollToTop'
import AnalyticProvider from './components/AnalyticService'
import ErrorBoundary from './components/ErrorBoundary'
const AppCustomerPortal = lazy(() => import('./pages-customer-portal/AppCustomerPortal'))

Auth.configure({
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    mandatorySignIn: false,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
})
Storage.configure({
    AWSS3: {
        bucket: process.env.REACT_APP_CUSTOMER_BUCKET,
        region: process.env.REACT_APP_AWS_REGION,
    },
})

const client = new AWSAppSyncClient({
    url: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || '',
    region: process.env.REACT_APP_AWS_REGION || '',
    auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
    },
    disableOffline: true,
    cacheOptions: {
        fragmentMatcher: new IntrospectionFragmentMatcher({
            introspectionQueryResultData: {
                __schema: {
                    types: [], // no types provided
                },
            },
        }),
        dataIdFromObject: (o: any): string | undefined => {
            switch (o.__typename) {
                case 'Banner':
                    return o.banner_id
                case 'PortfolioSection':
                    return o.portfolio_section_key
                case 'Collection':
                    return o.collection_id
                case 'CollectionAsset':
                    return o.asset_id
                case 'Slug':
                    return `${o.type}-${o.slug}`
                case 'Profile':
                    return o.page_key
                case 'Customer':
                    return o.customer_id
                default:
                    return o.id
            }
        },
    },
})

const styles = stylesheet({
    app: {
        minHeight: viewHeight(100),
        display: 'flex',
        flexDirection: 'column',
    },
    appBody: {
        flex: 1,
    },
})

Modal.setAppElement()

export default function App() {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <div className={styles.app}>
                    <ApolloProvider client={client as any}>
                        <div className={styles.appBody}>
                            <ScrollToTop />
                            <AnalyticProvider>
                                <Header />
                                <Suspense fallback={null}>
                                    <Switch>
                                        <Route exact path="/" component={PageHome} />
                                        <Route exact path="/portfolio" component={PagePortfolioList} />
                                        <Route exact path="/portfolio/:section" component={PagePortfolioSectionCollections} />
                                        <Route path="/portfolio/:section/:collectionSlug" component={PageCollectionAssetList} />
                                        <Route exact path="/projects" component={PageProjects} />
                                        <Route path="/projects/:collectionSlug" component={PageCollectionAssetList} />
                                        <Route path="/contact-me" component={PageContactMe} />
                                        <Route path="/client-portal" component={AppCustomerPortal} />
                                        <Route path="*">
                                            <Redirect to="/" />
                                        </Route>
                                    </Switch>
                                </Suspense>
                            </AnalyticProvider>
                        </div>
                        <Footer />
                    </ApolloProvider>
                </div>
            </BrowserRouter>
        </ErrorBoundary>
    )
}
