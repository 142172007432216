import React, { useState } from 'react'
import { Collection, Theme } from 'vo-components'
import { useForm, ValidationOptions } from 'react-hook-form'
import { useCustomerSendMessage } from '../graphql/useCustomerAction'
import { useSpring, animated } from 'react-spring'
import { classes, media, stylesheet } from 'typestyle'
import { LottieThankYou, LottieError } from './Lottie'
import { em } from 'csx'
import { useSendMessageMutation } from '../graphql/useMessage'
import { Link } from 'react-router-dom'

const styles = stylesheet({
    section: {
        paddingTop: em(5),
        paddingBottom: em(5),
    },
    headingContainer: {
        marginBottom: em(5),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    fieldsContainer: {
        ...media(Theme.screen.phone, {
            width: '100%',
        }),
    },
    field: {
        width: 800,
        maxWidth: '100%',
        ...media(Theme.screen.phone, {
            width: '100%',
        }),
    },

    input: {
        boxShadow: 'none',
        borderColor: Theme.colors.greyLighter,
        outlineColor: Theme.colors.greyLighter,
        maxWidth: '100%',
        $nest: {
            '&:focus': {
                borderColor: Theme.colors.greyLighter,
                boxShadow: '0 0 0 0.125em rgba(166, 166, 166, 0.25)',
            },
            '&::placeholder': {
                color: Theme.colors.greyLighter,
            },
        },
    },
    submitField: {
        marginTop: em(2),
        display: 'flex',
        justifyContent: 'center',
    },
    submitButton: {
        padding: '0 2em',
    },
    regardsContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
})

function ThankYou() {
    const containerStyle = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 650 },
        delay: 450,
    })
    return (
        <animated.div style={containerStyle} className={classes('is-overlay is-flex', styles.regardsContainer)}>
            <LottieThankYou height={225} />
            <h2 className="is-size-4 has-text-centered">I&apos;ll get back to you as soon as possible!</h2>
            <p className="has-text-centered is-size-5">
                In the meantime, you can&nbsp;
                <u>
                    <Link className="has-text-grey-dark" to="/">
                        explore my website
                    </Link>
                </u>
            </p>
        </animated.div>
    )
}

function FormError() {
    return (
        <div className={classes('is-overlay is-flex', styles.regardsContainer)}>
            <LottieError height={225} />
            <h2 className="is-size-4 has-text-centered">
                <strong>Sorry</strong>, there&lsquo;s something wrong with this form...
            </h2>
            <p className="has-text-centered">
                In the meantime, please contact me through my&nbsp;
                <a href="mailto:veronicaong.media@gmail.com">
                    <u>email</u>
                </a>
                &nbsp;or&nbsp;
                <a href="https://instagram.com/veeongg" target="__blank">
                    <u>instagram</u>
                </a>
                .
            </p>
        </div>
    )
}

interface CustomerContactFormProps {
    collection: Collection
}

interface CustomerContactFormModel {
    message: string
}

const customerContactFormValidations: { [key in keyof CustomerContactFormModel]: ValidationOptions } = {
    message: {
        required: {
            value: true,
            message: "Hey! Don't let your message be empty!",
        },
    },
}

export function CustomerContactForm({ collection }: CustomerContactFormProps) {
    const [isSuccess, setIsSuccess] = useState(false)
    const { register, handleSubmit, errors, watch } = useForm<CustomerContactFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            message: '',
        },
    })
    const { message } = watch()
    const [send, { loading, error }] = useCustomerSendMessage({
        collection,
        message,
        onCompleted: () => {
            setIsSuccess(true)
        },
    })
    const isFormHidden = isSuccess || !!error
    const fieldsContainerStyle = useSpring({ transform: isFormHidden ? 'translateY(-100%)' : 'translateY(0%)' })
    const buttonStyle = useSpring({ clipPath: isFormHidden ? 'inset(0% 100%)' : 'inset(0% 0%)' })
    return (
        <>
            <form
                className={classes(styles.form, 'is-relative')}
                onSubmit={handleSubmit(() => {
                    send().catch(console.error)
                })}
            >
                <animated.div className={styles.fieldsContainer} style={fieldsContainerStyle}>
                    <h3 className="is-size-3">Send us a message</h3>
                    <div className={classes('field', styles.field)}>
                        <label className="label is-family-secondary">Message&nbsp;*</label>
                        <div className="control">
                            <textarea
                                name="message"
                                ref={register(customerContactFormValidations.message)}
                                rows={10}
                                className={classes('textarea has-background-white is-radiusless', styles.input)}
                            />
                        </div>
                        {errors.message && <p className="help is-danger">{errors.message?.message}</p>}
                    </div>
                </animated.div>
                <div className={classes('field', styles.field, styles.submitField)}>
                    <animated.button
                        style={buttonStyle}
                        type="submit"
                        className={classes(
                            'button has-background-grey has-text-white-bis is-radiusless',
                            loading && 'is-loading',
                            styles.submitButton
                        )}
                    >
                        Send Message
                    </animated.button>
                </div>
                {error && <FormError />}
                {isSuccess && <ThankYou />}
            </form>
        </>
    )
}

interface GuestContactFormModel {
    name: string
    reply_to: string
    message: string
}

const guestContactFormValidations: { [key in keyof GuestContactFormModel]: ValidationOptions } = {
    name: {
        required: {
            value: true,
            message: 'What should I call you? Please enter your name :)',
        },
    },
    reply_to: {
        required: {
            value: true,
            message: 'Where can I reach you? Please enter your email address / instagram username :)',
        },
    },
    message: {
        required: {
            value: true,
            message: "Hey! Don't let your message be empty!",
        },
    },
}

const GUEST_CONTACT_FORM_LISTING_STATUS = 'CONTACT_ME'

export function GuestContactForm() {
    const [isSuccess, setIsSuccess] = useState(false)
    const { register, handleSubmit, errors, watch } = useForm<GuestContactFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            name: '',
            reply_to: '',
            message: '',
        },
    })
    const { name, reply_to, message } = watch()
    const [send, { loading, error }] = useSendMessageMutation({
        listingStatus: GUEST_CONTACT_FORM_LISTING_STATUS,
        name,
        reply_to,
        message,
        onCompleted: () => {
            setIsSuccess(true)
        },
    })
    const isFormHidden = isSuccess || !!error
    const fieldsContainerStyle = useSpring({ transform: isFormHidden ? 'translateY(-100%)' : 'translateY(0%)' })
    const buttonStyle = useSpring({ clipPath: isFormHidden ? 'inset(0% 100%)' : 'inset(0% 0%)' })
    return (
        <div className={classes('section has-background-white-bis', styles.section)}>
            <div className="container">
                <div className={styles.headingContainer}>
                    <h2 className="is-size-3-tablet is-size-4-mobile has-text-centered has-text-weight-bold is-uppercase has-letter-spacing title">
                        Get In Touch
                    </h2>
                    <p className="subtitle has-text-centered is-size-6-mobile">
                        <a className="is-family-secondary is-italic is-lowercase has-text-grey" href="mailto:veronicaong.media@gmail.com">
                            veronicaong.media@gmail.com
                        </a>
                        &nbsp;/&nbsp;
                        <a
                            className="is-family-secondary is-italic is-lowercase has-text-grey"
                            href="https://www.instagram.com/veeongg/"
                            target="__blank"
                        >
                            @veeongg
                        </a>
                    </p>
                </div>
                <div>
                    <form
                        className={classes(styles.form, 'is-relative')}
                        onSubmit={handleSubmit(() => {
                            send().catch(console.error)
                        })}
                    >
                        <animated.div className={styles.fieldsContainer} style={fieldsContainerStyle}>
                            <div className={classes('field', styles.field)}>
                                <label className="label is-family-secondary">Name&nbsp;*</label>
                                <div className="control">
                                    <input
                                        name="name"
                                        ref={register(guestContactFormValidations.name)}
                                        className={classes('input has-background-white is-radiusless', styles.input)}
                                        type="text"
                                    />
                                </div>
                                {errors.name && <p className="help is-danger">{errors.name?.message}</p>}
                            </div>
                            <div className={classes('field', styles.field)}>
                                <label className="label is-family-secondary">Email / Instagram Username&nbsp;*</label>
                                <div className="control">
                                    <input
                                        name="reply_to"
                                        ref={register(guestContactFormValidations.reply_to)}
                                        className={classes('input has-background-white is-radiusless', styles.input)}
                                        type="text"
                                    />
                                </div>
                                {errors.reply_to && <p className="help is-danger">{errors.reply_to?.message}</p>}
                            </div>
                            <div className={classes('field', styles.field)}>
                                <label className="label is-family-secondary">Message&nbsp;*</label>
                                <div className="control">
                                    <textarea
                                        name="message"
                                        ref={register(guestContactFormValidations.message)}
                                        rows={10}
                                        className={classes('textarea has-background-white is-radiusless', styles.input)}
                                    />
                                </div>
                                {errors.message && <p className="help is-danger">{errors.message?.message}</p>}
                            </div>
                        </animated.div>
                        <div className={classes('field', styles.field, styles.submitField)}>
                            <animated.button
                                style={buttonStyle}
                                type="submit"
                                className={classes(
                                    'button has-background-grey has-text-white-bis is-radiusless',
                                    loading && 'is-loading',
                                    styles.submitButton
                                )}
                            >
                                Send Message
                            </animated.button>
                        </div>
                        {error && <FormError />}
                        {isSuccess && <ThankYou />}
                    </form>
                </div>
            </div>
        </div>
    )
}
