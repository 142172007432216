import React, { useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import { classes, media, stylesheet } from 'typestyle'
import { em } from 'csx'
import Loading from '../../components/Loading'
import { useCollectionsSlugQuery } from '../../graphql/useCollections'
import { Theme, getDynamicImageURL, getMedia, MediaType, Collection, SlugCollection, CollectionAssetType } from 'vo-components'
import Media from '../../components/Media'
import Photos from './Photos'
import Helmet from 'react-helmet'
import striptags from 'striptags'

const styles = stylesheet({
    section: {
        ...media(Theme.screen.phone, {
            paddingLeft: em(1),
            paddingRight: em(1),
        }),
    },
    loadingTitleContainer: {
        marginTop: em(4),
        display: 'flex',
        justifyContent: 'center',
        marginBottom: em(8),
        ...media(Theme.screen.phone, {
            marginTop: 0,
        }),
    },
    loadingTitle: {
        width: em(7),
        height: em(4),
    },
    loadingContainer: {
        marginBottom: em(1),
    },
    titleContainer: {
        marginTop: em(2),
        marginBottom: em(4),
        ...media(Theme.screen.phone, {
            marginTop: 0,
            marginBottom: em(2),
        }),
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        $nest: {
            '&::after': {
                content: "''",
                height: 3,
                background: Theme.colors.black,
                display: 'block',
                marginTop: 12,
                transition: 'width 150ms ease-in-out',
                width: em(1),
            },
            '&:hover': {
                $nest: {
                    '&::after': {
                        width: em(1.5),
                    },
                },
            },
        },
        ...media(Theme.screen.phone, {
            marginTop: 0,
        }),
    },
    descriptionContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: em(2),
    },
    description: {
        maxWidth: '70%',
        ...media(Theme.screen.phone, {
            maxWidth: '100%',
        }),
    },
    image: {
        cursor: 'pointer',
        backgroundColor: Theme.colors.greyLight,
    },
    collectionTitle: {
        marginTop: em(0.5),
    },
    photosContainer: {
        marginTop: em(4),
    },
    videosContainer: {
        marginTop: em(4),
    },
    bannerContainer: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
})

interface CollectionAssetsProps {
    collection: Collection
}

function CollectionAssets({ collection }: CollectionAssetsProps) {
    const videos = collection.segments?.find(segment => segment.type === CollectionAssetType.Video)?.assets?.items.filter(video => !!video.file) || []
    return (
        <div className={classes('section', styles.section)}>
            <div className="container">
                <div className={styles.titleContainer}>
                    <p className="has-text-centered">
                        {collection.portfolio_section ? (
                            <Link
                                className="is-family-secondary has-text-dark is-italic is-lowercase"
                                to={`/portfolio/${collection.portfolio_section.slug}`}
                            >
                                {collection.portfolio_section.name}
                            </Link>
                        ) : (
                            <Link className="is-family-secondary has-text-dark is-italic is-lowercase" to="/projects">
                                Projects
                            </Link>
                        )}
                    </p>
                    <h1
                        className={classes(
                            'is-size-3-tablet is-size-4-mobile has-text-centered has-text-weight-bold is-uppercase has-letter-spacing',
                            styles.title
                        )}
                    >
                        {collection.title}
                    </h1>
                    {collection.description && (
                        <div className={styles.descriptionContainer}>
                            <div
                                className={classes('content has-text-centered', styles.description)}
                                dangerouslySetInnerHTML={{ __html: collection.description }}
                            />
                        </div>
                    )}
                </div>
                {collection.banner && (
                    <div className={styles.bannerContainer}>
                        <figure className="image is-16by9">
                            <Media playing title={`Banner for ${collection.title}`} file={collection.banner || undefined} imageWidth={1920} />
                        </figure>
                    </div>
                )}

                {videos.length > 0 && (
                    <div className={classes('columns is-multiline is-centered', styles.videosContainer)}>
                        {videos.map(video => (
                            <div key={video.asset_id} className="column is-6-desktop">
                                <Media playing title={video.asset_id} file={video.file || undefined} />
                            </div>
                        ))}
                    </div>
                )}

                <Photos collection={collection} />
            </div>
        </div>
    )
}

export default function PageCollectionAssetList() {
    const { collectionSlug } = useParams()
    const { loading, data } = useCollectionsSlugQuery({ slug: collectionSlug || '', types: [CollectionAssetType.Image, CollectionAssetType.Video] })
    const { collection } = (data?.showSlug?.value || {}) as SlugCollection
    const strippedDescription = useMemo(() => striptags(collection?.description || ''), [collection])
    if (loading) {
        return (
            <div className={classes('section', styles.section)}>
                <div className="container">
                    <div className={styles.loadingTitleContainer}>
                        <Loading>
                            <div className={styles.loadingTitle} />
                        </Loading>
                    </div>
                    <div>
                        <Loading>
                            <figure className="image is-16by9">
                                <div className="has-ratio" />
                            </figure>
                        </Loading>
                    </div>
                    <div className={styles.photosContainer}>
                        <div className="columns is-multiline">
                            {Array(3)
                                .fill(null)
                                .map((_, index) => (
                                    <div key={`loading-bar-assets-${index}`} className="column is-4">
                                        <div className={styles.loadingContainer}>
                                            <Loading>
                                                <figure className="image is-1by1">
                                                    <div className="has-ratio" />
                                                </figure>
                                            </Loading>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    if (!collection) return null
    const bannerType = getMedia(collection.banner?.contentType || '')
    return (
        <>
            <Helmet>
                <title>{collection.title || ''} | Veronica Onggoro</title>
                <meta property="og:title" content={collection.title || ''} />
                {bannerType === MediaType.IMAGE && (
                    <meta property="og:image" content={getDynamicImageURL({ src: collection.banner?.public_url || '', width: 480 })} />
                )}
                {bannerType === MediaType.VIDEO && (
                    <Helmet>
                        <meta property="og:video" content={collection.banner?.public_url || ''} />
                        <meta property="og:video:secure_url" content={collection.banner?.public_url || ''} />
                        <meta property="og:video:type" content="video/mp4" />
                        <meta property="og:video:width" content="1920" />
                        <meta property="og:video:height" content="1080" />
                        {collection.banner?.poster && (
                            <meta property="og:image" content={getDynamicImageURL({ src: collection.banner?.poster || '', width: 480 })} />
                        )}
                    </Helmet>
                )}
                {strippedDescription && (
                    <Helmet>
                        <meta property="og:description" content={strippedDescription} />
                        <meta name="description" content={strippedDescription} />
                    </Helmet>
                )}
            </Helmet>
            <CollectionAssets collection={collection} />
        </>
    )
}
