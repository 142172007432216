import {
    CollectionAssetType,
    MutationCustomerSelectAssetArgs,
    Mutation,
    CUSTOMER_SELECT_ASSET,
    CUSTOMER_DESELECT_ASSET,
    MutationCustomerDeselectAssetArgs,
    LIST_COLLECTION_ASSETS,
    CollectionAssetConnection,
    QueryListCollectionAssetsArgs,
    CollectionAsset,
    CUSTOMER_UPDATE_COLLECTION_PROGRESS_STATUS,
    MutationCustomerUpdateCollectionProgressStatusArgs,
    Collection,
    Customer,
    SHOW_CUSTOMER_SELF,
    CUSTOMER_SEND_MESSAGE,
    MutationCustomerSendMessageArgs,
    MutationCustomerSendReviewArgs,
    CUSTOMER_SEND_REVIEW,
    CUSTOMER_DOWNLOAD_FINAL_ASSETS,
    MutationCustomerDownloadFinalAssetsArgs,
    CUSTOMER_VIEW_TUTORIAL,
    MutationCustomerRequestDownloadIndividualAssetArgs,
    CUSTOMER_REQUEST_DOWNLOAD_INDIVIDUAL_ASSET,
    QueryCustomerGetDownloadRequestArgs,
    CUSTOMER_GET_DOWNLOAD_REQUEST,
    Query,
} from 'vo-components'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import Maybe from 'graphql/tsutils/Maybe'

interface SelectAssetMutationArgs {
    asset?: CollectionAsset
    type: CollectionAssetType
    onCompleted?: () => void
}

export function useCustomerSelectAssetMutation({ asset, type, onCompleted }: SelectAssetMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCustomerSelectAssetArgs>(CUSTOMER_SELECT_ASSET, {
        onCompleted,
        variables: {
            input: {
                asset_id: asset?.asset_id || '',
                sort_key: asset?.sort_key || '',
                type,
            },
        },
        optimisticResponse: asset && {
            __typename: 'Mutation',
            customerSelectAsset: {
                __typename: 'CollectionAsset',
                ...asset,
                asset_id: new Date().toISOString(),
                type,
                source_asset_id: asset.asset_id,
            },
        },
        update: (cache, { data: selectedAssetData }) => {
            if (!selectedAssetData?.customerSelectAsset) return
            try {
                const cacheQuery = {
                    query: LIST_COLLECTION_ASSETS,
                    variables: { collection_id: asset?.collection_id || '', type },
                }
                const data = cache.readQuery<{ listCollectionAssets: Maybe<CollectionAssetConnection> }, QueryListCollectionAssetsArgs>({
                    ...cacheQuery,
                })
                if (!data?.listCollectionAssets) return
                cache.writeQuery<{ listCollectionAssets: Maybe<CollectionAssetConnection> }, QueryListCollectionAssetsArgs>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        listCollectionAssets: {
                            ...data.listCollectionAssets,
                            items: [...data.listCollectionAssets.items, selectedAssetData.customerSelectAsset],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomerSelectAssetMutation`')
            }
        },
    })
}

interface DeselectAssetMutationArgs {
    asset?: CollectionAsset
    onCompleted?: () => void
}

export function useCustomerDeselectAssetMutation({ asset, onCompleted }: DeselectAssetMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCustomerDeselectAssetArgs>(CUSTOMER_DESELECT_ASSET, {
        onCompleted,
        variables: {
            asset_id: asset?.asset_id || '',
        },
        optimisticResponse: asset && {
            __typename: 'Mutation',
            customerDeselectAsset: {
                __typename: 'CollectionAsset',
                ...asset,
            },
        },
        update: (cache, { data: deselectedAssetData }) => {
            if (!deselectedAssetData?.customerDeselectAsset) return
            try {
                const cacheQuery = {
                    query: LIST_COLLECTION_ASSETS,
                    variables: { collection_id: asset?.collection_id || '', type: asset?.type },
                }
                const data = cache.readQuery<{ listCollectionAssets: Maybe<CollectionAssetConnection> }, QueryListCollectionAssetsArgs>({
                    ...cacheQuery,
                })
                if (!data?.listCollectionAssets) return
                cache.writeQuery<{ listCollectionAssets: Maybe<CollectionAssetConnection> }, QueryListCollectionAssetsArgs>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        listCollectionAssets: {
                            ...data.listCollectionAssets,
                            items: data.listCollectionAssets.items.filter(
                                collectionAsset => collectionAsset.asset_id !== deselectedAssetData.customerDeselectAsset?.asset_id
                            ),
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomerDeselectAssetMutation`')
            }
        },
    })
}

interface CustomerUpdateCollectionProgressStatusMutationArgs {
    collection?: Collection
    customer_is_done: boolean
    onCompleted?: () => void
}

export function useCustomerUpdateCollectionProgressStatus({
    collection,
    customer_is_done,
    onCompleted,
}: CustomerUpdateCollectionProgressStatusMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCustomerUpdateCollectionProgressStatusArgs>(CUSTOMER_UPDATE_COLLECTION_PROGRESS_STATUS, {
        onCompleted,
        variables: {
            collection_id: collection?.collection_id || '',
            input: {
                customer_is_done,
            },
        },
        update: (cache, { data: updatedCollectionData }) => {
            if (!updatedCollectionData?.customerUpdateCollectionProgressStatus) return
            try {
                const cacheQuery = {
                    query: SHOW_CUSTOMER_SELF,
                }
                const data = cache.readQuery<{ showCustomerSelf: Maybe<Customer> }>({
                    ...cacheQuery,
                })
                if (!data?.showCustomerSelf) return
                cache.writeQuery<{ showCustomerSelf: Maybe<Customer> }>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        showCustomerSelf: {
                            ...data.showCustomerSelf,
                            latestSession: data.showCustomerSelf.latestSession && {
                                ...data.showCustomerSelf.latestSession,
                                customer_is_done,
                            },
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomerUpdateCollectionProgressStatus`')
            }
        },
    })
}

interface CustomerSendMessageMutationArgs {
    collection?: Collection
    message: string
    onCompleted?: () => void
}

export function useCustomerSendMessage({ collection, message, onCompleted }: CustomerSendMessageMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCustomerSendMessageArgs>(CUSTOMER_SEND_MESSAGE, {
        onCompleted,
        variables: {
            input: {
                listing_status: 'CUSTOMER_IN_PROGRESS',
                collection_id: collection?.collection_id || '',
                message,
            },
        },
        update: (cache, { data: newMessage }) => {
            if (!newMessage?.customerSendMessage) return
            try {
                const cacheQuery = {
                    query: SHOW_CUSTOMER_SELF,
                }
                const data = cache.readQuery<{ showCustomerSelf: Maybe<Customer> }>({
                    ...cacheQuery,
                })
                if (!data?.showCustomerSelf) return
                cache.writeQuery<{ showCustomerSelf: Maybe<Customer> }>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        showCustomerSelf: {
                            ...data.showCustomerSelf,
                            latestSession: data.showCustomerSelf.latestSession && {
                                ...data.showCustomerSelf.latestSession,
                                customer_messages: {
                                    ...data.showCustomerSelf.latestSession.customer_messages,
                                    items: [...(data.showCustomerSelf.latestSession.customer_messages?.items || []), newMessage.customerSendMessage],
                                },
                            },
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomerSendMessage`')
            }
        },
    })
}

interface CustomerSendReviewMutationArgs {
    collection?: Collection
    rating: string
    comment?: string
    onCompleted?: () => void
}

export function useCustomerSendReview({ collection, rating, comment, onCompleted }: CustomerSendReviewMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCustomerSendReviewArgs>(CUSTOMER_SEND_REVIEW, {
        onCompleted,
        variables: {
            collection_id: collection?.collection_id || '',
            input: {
                rating,
                comment: comment || undefined,
            },
        },
        update: (cache, { data: newReview }) => {
            if (!newReview?.customerSendReview) return
            try {
                const cacheQuery = {
                    query: SHOW_CUSTOMER_SELF,
                }
                const data = cache.readQuery<{ showCustomerSelf: Maybe<Customer> }>({
                    ...cacheQuery,
                })
                if (!data?.showCustomerSelf) return
                cache.writeQuery<{ showCustomerSelf: Maybe<Customer> }>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        showCustomerSelf: {
                            ...data.showCustomerSelf,
                            latestSession: data.showCustomerSelf.latestSession && {
                                ...data.showCustomerSelf.latestSession,
                                // We are setting the review to null
                                // because we don't want the feedback card to disappear once the customer has given feedback.
                                // Otherwise apollo will update the cache and the thank you message will not have a chance to appear
                                customer_review: null,
                            },
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomerSendReview`')
            }
        },
    })
}

interface CustomerDownloadAssetsMutationArgs {
    collection?: Collection
    onCompleted?: () => void
}

export function useCustomerDownloadAssets({ collection, onCompleted }: CustomerDownloadAssetsMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCustomerDownloadFinalAssetsArgs>(CUSTOMER_DOWNLOAD_FINAL_ASSETS, {
        onCompleted,
        variables: {
            collection_id: collection?.collection_id || '',
        },
    })
}

interface CustomerViewTutorialMutationArgs {
    onCompleted?: () => void
}

export function useCustomerViewTutorial({ onCompleted }: CustomerViewTutorialMutationArgs) {
    return useMutation<Partial<Mutation>, CustomerViewTutorialMutationArgs>(CUSTOMER_VIEW_TUTORIAL, {
        onCompleted,
    })
}

interface CustomerRequestDownloadIndividualAssetMutationArgs {
    onCompleted?: (data: Partial<Mutation>) => void
    asset_id: string
}

export function useCustomerRequestDownloadIndividualAssetMutation({ onCompleted, asset_id }: CustomerRequestDownloadIndividualAssetMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCustomerRequestDownloadIndividualAssetArgs>(CUSTOMER_REQUEST_DOWNLOAD_INDIVIDUAL_ASSET, {
        onCompleted,
        variables: {
            asset_id,
        },
    })
}

interface CustomerGetDownloadRequestQueryArgs {
    onCompleted?: () => void
}

export function useCustomerGetDownloadRequestQuery({ onCompleted }: CustomerGetDownloadRequestQueryArgs) {
    return useLazyQuery<Partial<Query>, QueryCustomerGetDownloadRequestArgs>(CUSTOMER_GET_DOWNLOAD_REQUEST, {
        onCompleted,
    })
}
