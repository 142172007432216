import React from 'react'
import { Lottie } from 'vo-components'
import thankYou from '../assets/lottiefiles/thank-you.json'
import error from '../assets/lottiefiles/error.json'
import banana from '../assets/lottiefiles/banana.json'
import avatar from '../assets/lottiefiles/vo-avatar.json'
import editingInProgress from '../assets/lottiefiles/editing-in-progress.json'
import tutorialPickPhoto from '../assets/lottiefiles/tutorial-pick-photo.json'

interface LottieProps {
    height?: string | number
}

export function LottieThankYou({ height }: LottieProps) {
    return <Lottie height={height} autoplay animationData={thankYou} />
}

export function LottieError({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={error} />
}

export function LottieBanana({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={banana} />
}

export function LottieAvatar({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={avatar} />
}

export function LottieEditingInProgress({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={editingInProgress} />
}

export function LottieTutorialPickPhoto({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={tutorialPickPhoto} />
}
