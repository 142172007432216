import { useQuery } from '@apollo/react-hooks'
import {
    SHOW_SLUG,
    LIST_COLLECTIONS_BY_LISTING_STATUS,
    SlugType,
    Maybe,
    Slug,
    QueryShowSlugArgs,
    CollectionConnection,
    QueryListCollectionsByListingStatusArgs,
    CollectionSegmentsArgs,
} from 'vo-components'

interface CollectionsSlugQueryArgs {
    slug: string
}

export function useCollectionsSlugQuery({ slug, types }: CollectionsSlugQueryArgs & CollectionSegmentsArgs) {
    return useQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>(SHOW_SLUG, {
        variables: { type: SlugType.Collection, slug, types },
        skip: !slug,
    })
}

interface CollectionsQueryArgs {
    listingStatus: string
}

export function useCollectionsQuery({ listingStatus }: CollectionsQueryArgs) {
    return useQuery<{ listCollectionsByListingStatus: Maybe<CollectionConnection> }, QueryListCollectionsByListingStatusArgs>(
        LIST_COLLECTIONS_BY_LISTING_STATUS,
        {
            variables: { listing_status: listingStatus },
        }
    )
}
