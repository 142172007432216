import React, { useState, useCallback, useRef } from 'react'
import { classes, media, stylesheet } from 'typestyle'
import { important, percent } from 'csx'
import { animated, useTransition, useSpring } from 'react-spring'
import { Theme, DynamicImage, Banner } from 'vo-components'
import { useInterval, useHoverDirty } from 'react-use'
import { useDrag } from 'react-use-gesture'
import { isMobile } from 'react-device-detect'
import { useBannersQuery } from '../../graphql/useBanners'
import Loading from '../../components/Loading'

const styles = stylesheet({
    arrow: {
        top: '50%',
        padding: '50px 10px',
        transform: 'translateY(-50%)',
        position: 'absolute',
        zIndex: 10,
        border: 'none',
        backgroundColor: 'transparent',
        color: Theme.colors.whiteBis,
        $nest: {
            '&:focus': {
                boxShadow: important('none'),
            },
            '&:hover,&:focus': {
                color: 'white',
            },
        },
        ...media(Theme.screen.phone, {
            padding: '0 10px',
        }),
    },
    arrowLeft: {
        left: 20,
    },
    arrowRight: {
        right: 20,
    },
    gallery: {
        overflow: 'hidden',
        position: 'relative',
        width: percent(100),
        height: percent(100),
    },
    image: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    banner: {
        touchAction: 'pan-y',
    },
})

interface BannersProps {
    banners: Banner[]
}

function Banners({ banners }: BannersProps) {
    const ref = useRef(null)
    const [index, setIndex] = useState(0)
    const [isNextTransition, setIsNextTransition] = useState(true)
    const isHovering = useHoverDirty(ref)
    const next = useCallback(() => {
        setIsNextTransition(true)
        setIndex(index => (index + 1) % banners.length)
    }, [banners.length])
    const previous = useCallback(() => {
        setIsNextTransition(false)
        setIndex(index => (index > 0 ? index - 1 : banners.length - 1))
    }, [banners.length])
    useInterval(
        () => {
            next()
        },
        !isHovering && !isMobile ? 4750 : null
    )
    const bind = useDrag(
        ({ down, direction: [xDir], elapsedTime }) => {
            // check for elapsedTime because a tap will trigger this handler twice.
            // the second trigger would have an elapsedTime of ~0
            if (down || elapsedTime < 2) return
            if (xDir <= 0) {
                next()
            } else if (xDir > 0) {
                previous()
            }
        },
        { axis: 'x' }
    )
    const transitions = useTransition(index, p => p, {
        from: {
            opacity: 0,
            transform: `translateX(${isNextTransition ? '' : '-'}100%)`,
        },
        enter: { opacity: 1, transform: 'translateX(0)' },
        leave: {
            opacity: 0,
            transform: `translateX(${isNextTransition ? '-' : ''}100%)`,
        },
        config: { mass: 1, tension: 120, friction: 14, duration: 750 },
    })
    const buttonStyle = useSpring({ opacity: isHovering ? 1 : 0 })
    if ((banners || []).length === 0) return null
    return (
        <div ref={ref} className={styles.gallery}>
            {!isMobile && (
                <animated.button onClick={() => previous()} style={buttonStyle} className={classes('button', styles.arrow, styles.arrowLeft)}>
                    <span className="icon is-size-3 is-small">
                        <i className="fas fa-chevron-left" />
                    </span>
                </animated.button>
            )}
            <figure {...bind()} className={classes(`image is-${isMobile ? '4by5' : '16by9'}`, styles.banner)}>
                {transitions.map(({ item, props, key }) => {
                    return (
                        <animated.div key={key} style={props} className={styles.image}>
                            <DynamicImage
                                src={banners[item].file?.public_url || ''}
                                width={1920}
                                alt={`Banner ${item}`}
                                srcSet={[
                                    { maxScreenWidth: 480, imageWidth: 1080 },
                                    { maxScreenWidth: 720, imageWidth: 1280 },
                                ]}
                            />
                        </animated.div>
                    )
                })}
            </figure>
            <div className="is-hidden">
                {banners.map((banner, index) => (
                    <img alt="Hidden Banner" key={`image-${index}`} src={banner.file?.public_url || undefined} />
                ))}
            </div>
            {!isMobile && (
                <animated.button onClick={() => next()} style={buttonStyle} className={classes('button', styles.arrow, styles.arrowRight)}>
                    <span className="icon is-size-3 is-small">
                        <i className="fas fa-chevron-right" />
                    </span>
                </animated.button>
            )}
        </div>
    )
}

export default function LandingBanner() {
    const { loading, data } = useBannersQuery()
    if (loading) {
        return (
            <Loading>
                <figure className={classes(`image is-${isMobile ? '4by5' : '16by9'}`)}>
                    <div className="has-ratio" />
                </figure>
            </Loading>
        )
    }

    return <Banners banners={data?.listBanners?.items || []} />
}
