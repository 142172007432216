import React, { useEffect } from 'react'
import GoogleAnalytics from 'react-ga'
import { useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useEffectOnce } from 'react-use'

if (process.env.NODE_ENV === 'production') {
    GoogleAnalytics.initialize('UA-142872440-2')
}

function usePageAnalytics() {
    const location = useLocation()
    useEffect(() => {
        GoogleAnalytics.pageview(location.pathname)
    }, [location])
    return null
}

export const AnalyticContext = React.createContext(null)

interface AnalyticProviderProps {
    children: React.ReactNode
}

export default function AnalyticProvider({ children }: AnalyticProviderProps) {
    useEffectOnce(() => {
        Auth.currentCredentials().then(currentCredentials => {
            GoogleAnalytics.set({
                userId: currentCredentials.identityId,
            })
        })
    })
    usePageAnalytics()
    return <AnalyticContext.Provider value={null}>{children}</AnalyticContext.Provider>
}
