import React, { useMemo, useState } from 'react'
import { Modal, Theme } from 'vo-components'
import { classes, media, stylesheet } from 'typestyle'
import { viewWidth, percent, em } from 'csx'
import Select, { ValueType } from 'react-select'
import { useForm } from 'react-hook-form'
import { useEffectOnce, useError } from 'react-use'
import { useSendWebsiteFeedbackMutation } from '../graphql/useMessage'
import Error from './Error'
import { LottieThankYou } from './Lottie'

const styles = stylesheet({
    modal: {
        width: viewWidth(40),
        overflow: 'visible',
        ...media(Theme.screen.phone, {
            width: viewWidth(100),
        }),
    },
    container: {
        height: percent(100),
        padding: em(1),
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
})

interface ModalFeedbackProps {
    isOpen: boolean
    onClose: () => void
}

enum FeedbackType {
    COMMENT = 'General Comment',
    SUGGESTION = 'Suggestion for Improvement',
    REPORT_BUG = 'Bug Report',
}

const feedbackLabelMap = new Map<FeedbackType, string>([
    [FeedbackType.COMMENT, "Let us know what's on your mind"],
    [FeedbackType.SUGGESTION, 'What improvements would you like to see?'],
    [FeedbackType.REPORT_BUG, 'Describe the bug or issue'],
])

interface FeedbackTypeOption {
    label: string
    value: FeedbackType
}

const FEEDBACK_TYPES: FeedbackTypeOption[] = [
    { value: FeedbackType.COMMENT, label: 'Leave a comment' },
    { value: FeedbackType.SUGGESTION, label: 'Suggest an improvement' },
    { value: FeedbackType.REPORT_BUG, label: 'Report a bug' },
]

interface FeedbackFormModel {
    type?: FeedbackType
    message?: string
}

export default function ModalFeedback({ isOpen, onClose }: ModalFeedbackProps) {
    const dispatchError = useError()
    const { register, handleSubmit, watch, setValue, reset } = useForm<FeedbackFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
    })
    const resetAndCloseModal = () => {
        reset()
        onClose()
    }
    const { type, message } = watch()
    const [isSuccess, setIsSuccess] = useState(false)
    const [send, { loading, error }] = useSendWebsiteFeedbackMutation({
        type,
        message,
        onCompleted: () => {
            setIsSuccess(true)
        },
    })
    const selectedOption = useMemo(() => FEEDBACK_TYPES.find(option => option.value === type), [type])
    useEffectOnce(() => {
        register({ name: 'type', required: true })
    })
    const activeLabel = useMemo(() => type && feedbackLabelMap.get(type), [type])
    return (
        <Modal isOpen={isOpen} onClose={resetAndCloseModal} className={styles.modal}>
            <div className={classes('columns', 'is-marginless', styles.container)}>
                <div className={classes('column', styles.innerContainer)}>
                    {isSuccess ? (
                        <LottieThankYou height={250} />
                    ) : (
                        <>
                            <h1 className="is-size-4 title">Share your thoughts</h1>
                            <form
                                onSubmit={handleSubmit(() => {
                                    send().catch(dispatchError)
                                })}
                            >
                                <div className="field">
                                    <label className="label has-text-weight-normal">I want to...</label>
                                    <div className="control">
                                        <Select
                                            aria-label="I want to..."
                                            classNamePrefix="select"
                                            value={selectedOption}
                                            options={FEEDBACK_TYPES}
                                            onChange={(selectedOption: ValueType<FeedbackTypeOption>) => {
                                                const option = selectedOption as FeedbackTypeOption | undefined
                                                setValue('type', option?.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {activeLabel && (
                                    <div className="field">
                                        <label className="label has-text-weight-normal">
                                            {activeLabel}
                                            <span className="has-text-danger">*</span>
                                        </label>
                                        <div className="control">
                                            <textarea ref={register({ required: true })} name="message" className="textarea has-fixed-size" />
                                        </div>
                                    </div>
                                )}
                                <div className="level" style={{ marginTop: em(2) }}>
                                    <div className="level-left">{error && <Error isCompact />}</div>
                                    <div className="level-right">
                                        <div className="field is-grouped is-grouped-right">
                                            <div className="control">
                                                <button
                                                    type="submit"
                                                    disabled={!type || !message}
                                                    className={classes('button is-link', loading && 'is-loading')}
                                                >
                                                    Send feedback
                                                </button>
                                            </div>
                                            <div className="control">
                                                <button onClick={() => resetAndCloseModal()} type="button" className="button is-link is-light">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    )
}
