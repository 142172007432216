import React, { useState, useEffect } from 'react'
import { classes, media, stylesheet } from 'typestyle'
import { em, important, percent } from 'csx'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { Theme } from 'vo-components'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { useSpring, animated } from 'react-spring'
import { isMobile } from 'react-device-detect'

const navbarLineStyle = {
    content: "''",
    height: 2,
    background: Theme.colors.black,
    display: 'block',
    marginTop: 2,
}

const styles = stylesheet({
    navbar: {
        marginTop: em(2),
        ...media(Theme.screen.phone, {
            marginTop: em(1),
        }),
    },
    brandContainer: {
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        ...media(Theme.screen.phone, {
            paddingLeft: 12,
        }),
    },
    navbarItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        letterSpacing: 2,
        fontSize: em(0.75),
        $nest: {
            '&:hover': {
                backgroundColor: important('white'),
                color: important(Theme.colors.black),
                $nest: {
                    '&::after': {
                        ...navbarLineStyle,
                        transform: 'scaleX(1)',
                    },
                },
            },
            '&::after': {
                content: "''",
                transition: 'transform 350ms ease-in-out',
                transform: 'scaleX(0)',
                width: percent(100),
            },
            '&:focus': {
                backgroundColor: important('white'),
                color: important(Theme.colors.black),
            },
        },
    },
    navbarItemActive: {
        color: important(Theme.colors.black),
        $nest: {
            '&::after': {
                ...navbarLineStyle,
                width: percent(100),
                transform: 'scaleX(1)',
            },
        },
    },
})

function Brand() {
    const [isHovering, setIsHovering] = useState(false)
    const brandFullNameStyle = useSpring({
        transform: isHovering || isMobile ? 'translateX(5%)' : 'translateX(-100%)',
    })
    return (
        <Link
            to="/"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            className={classes('navbar-item', styles.brandContainer)}
        >
            <div style={{ zIndex: 1 }} className="has-background-white">
                <Logo title="Veronica Onggoro Logo" height={isMobile ? 50 : 65} />
            </div>
            <animated.div style={brandFullNameStyle}>
                <h1 className="has-text-black has-text-weight-bold is-uppercase has-letter-spacing">
                    VERONICA
                    <br />
                    ONGGORO
                </h1>
            </animated.div>
        </Link>
    )
}

export default function Header() {
    const [isActive, setIsActive] = useState(false)
    const location = useLocation()
    useEffect(() => {
        setIsActive(false)
    }, [location])
    return (
        <nav className={classes('navbar', styles.navbar)} role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <Brand />
                    <button
                        onClick={() => {
                            setIsActive(!isActive)
                        }}
                        className={classes('button is-white navbar-burger burger', isActive && 'is-active')}
                    >
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                    </button>
                </div>

                <div className={classes('navbar-menu', isActive && 'is-active')}>
                    <div className="navbar-end">
                        <NavLink
                            to="/"
                            className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                            exact
                            activeClassName={styles.navbarItemActive}
                        >
                            HOME
                        </NavLink>
                        <NavLink
                            to="/portfolio"
                            className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                            activeClassName={styles.navbarItemActive}
                        >
                            PORTFOLIO
                        </NavLink>
                        <NavLink
                            to="/projects"
                            className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                            activeClassName={styles.navbarItemActive}
                        >
                            PROJECTS
                        </NavLink>
                        <NavLink
                            to="/contact-me"
                            className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                            activeClassName={styles.navbarItemActive}
                        >
                            CONTACT ME
                        </NavLink>
                        <NavLink
                            to="/client-portal"
                            className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                            activeClassName={styles.navbarItemActive}
                        >
                            CLIENT PORTAL
                        </NavLink>
                    </div>
                </div>
            </div>
        </nav>
    )
}
