import React from 'react'
import { classes, media, stylesheet } from 'typestyle'
import { em } from 'csx'
import Loading from '../../components/Loading'
import { Theme } from 'vo-components'
import Collections from './Collections'
import { useCollectionsQuery } from '../../graphql/useCollections'
import Helmet from 'react-helmet'

const styles = stylesheet({
    loadingTitleContainer: {
        marginTop: em(4),
        display: 'flex',
        justifyContent: 'center',
        marginBottom: em(8),
        ...media(Theme.screen.phone, {
            marginTop: 0,
        }),
    },
    loadingTitle: {
        width: em(7),
        height: em(4),
    },
    loadingContainer: {
        marginBottom: em(1),
    },
    title: {
        marginTop: em(2),
        marginBottom: em(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        $nest: {
            '&::after': {
                content: "''",
                height: 3,
                background: Theme.colors.black,
                display: 'block',
                marginTop: 12,
                transition: 'width 150ms ease-in-out',
                width: em(1),
            },
            '&:hover': {
                $nest: {
                    '&::after': {
                        width: em(1.5),
                    },
                },
            },
        },
        ...media(Theme.screen.phone, {
            marginTop: 0,
            marginBottom: em(2),
        }),
    },
})

const LISTING_STATUS = 'PROJECTS'

const META_DESCRIPTION = 'Interested in collaborating? Veronica is open to collaboration with brands or other photographers.'

export default function PageProjects() {
    const { loading, data } = useCollectionsQuery({ listingStatus: LISTING_STATUS })
    const { items } = data?.listCollectionsByListingStatus || {}
    if (loading) {
        return (
            <div className="section">
                <div className="container">
                    <div className={styles.loadingTitleContainer}>
                        <Loading>
                            <div className={styles.loadingTitle} />
                        </Loading>
                    </div>
                    <div className="columns is-multiline">
                        {Array(6)
                            .fill(null)
                            .map((_, index) => (
                                <div key={`loading-bar-collections-${index}`} className="column is-6">
                                    <div className={styles.loadingContainer}>
                                        <Loading>
                                            <figure className="image is-16by9">
                                                <div className="has-ratio" />
                                            </figure>
                                        </Loading>
                                    </div>
                                    <Loading>
                                        <h2>title for loading</h2>
                                    </Loading>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <Helmet>
                <title>Projects | Veronica Onggoro</title>
                <meta property="og:title" content="Projects" />
                <meta property="og:description" content={META_DESCRIPTION} />
                <meta name="description" content={META_DESCRIPTION} />
            </Helmet>
            <div className="section">
                <div className="container">
                    <h1
                        className={classes(
                            'is-size-3-tablet is-size-4-mobile has-text-centered has-text-weight-bold is-uppercase has-letter-spacing',
                            styles.title
                        )}
                    >
                        PROJECTS
                    </h1>
                    <Collections baseUrl="/projects" collections={items || []} />
                </div>
            </div>
        </>
    )
}
