import React from 'react'
import { usePortfolioSectionsSlugQuery } from '../../graphql/usePortfolioSections'
import { useParams, Link } from 'react-router-dom'
import { media, stylesheet } from 'typestyle'
import { em } from 'csx'
import Loading from '../../components/Loading'
import { Theme, getDynamicImageURL, SlugPortfolioSection } from 'vo-components'
import Collections from './Collections'
import Helmet from 'react-helmet'

const styles = stylesheet({
    loadingTitleContainer: {
        marginTop: em(4),
        display: 'flex',
        justifyContent: 'center',
        marginBottom: em(8),
        ...media(Theme.screen.phone, {
            marginTop: 0,
        }),
    },
    loadingTitle: {
        width: em(7),
        height: em(4),
    },
    loadingContainer: {
        marginBottom: em(1),
    },
    titleContainer: {
        marginTop: em(2),
        marginBottom: em(4),
        ...media(Theme.screen.phone, {
            marginTop: 0,
            marginBottom: em(2),
        }),
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        $nest: {
            '&::after': {
                content: "''",
                height: 3,
                background: Theme.colors.black,
                display: 'block',
                marginTop: 12,
                transition: 'width 150ms ease-in-out',
                width: em(1),
            },
            '&:hover': {
                $nest: {
                    '&::after': {
                        width: em(1.5),
                    },
                },
            },
        },
        ...media(Theme.screen.phone, {
            marginTop: 0,
        }),
    },
})

export default function PagePortfolioSectionCollections() {
    const { section } = useParams()
    const { loading, data } = usePortfolioSectionsSlugQuery({ slug: section || '' })
    const { portfolio_section } = (data?.showSlug?.value || {}) as SlugPortfolioSection
    if (loading) {
        return (
            <div className="section">
                <div className="container">
                    <div className={styles.loadingTitleContainer}>
                        <Loading>
                            <div className={styles.loadingTitle} />
                        </Loading>
                    </div>
                    <div className="columns is-multiline">
                        {Array(6)
                            .fill(null)
                            .map((_, index) => (
                                <div key={`loading-bar-collections-${index}`} className="column is-6">
                                    <div className={styles.loadingContainer}>
                                        <Loading>
                                            <figure className="image is-16by9">
                                                <div className="has-ratio" />
                                            </figure>
                                        </Loading>
                                    </div>
                                    <Loading>
                                        <h2>title for loading</h2>
                                    </Loading>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        )
    }
    if (!portfolio_section) return null
    return (
        <>
            <Helmet>
                <title>{portfolio_section.name} | Veronica Onggoro</title>
                <meta property="og:title" content={portfolio_section.name || ''} />
                {portfolio_section.banner?.public_url && (
                    <meta property="og:image" content={getDynamicImageURL({ src: portfolio_section.banner?.public_url || '', width: 720 })} />
                )}
            </Helmet>
            <div className="section">
                <div className="container">
                    <div className={styles.titleContainer}>
                        <p className="has-text-centered">
                            <Link className="is-family-secondary has-text-dark is-italic is-lowercase" to="/portfolio">
                                portfolio
                            </Link>
                        </p>
                        <h1 className="is-size-3-tablet is-size-4-mobile has-text-centered has-text-weight-bold is-uppercase has-letter-spacing">
                            {portfolio_section.name}
                        </h1>
                    </div>

                    <Collections baseUrl={`/portfolio/${section}`} collections={portfolio_section.collections?.items || []} />
                </div>
            </div>
        </>
    )
}
