import { Component } from 'react'
import GoogleAnalytics from 'react-ga'

export default class ErrorBoundary extends Component {
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {}
    }

    componentDidCatch(error: Error) {
        // log the error to our server with loglevel
        GoogleAnalytics.exception({
            description: error.message,
            fatal: true,
        })
    }

    render() {
        return this.props.children
    }
}
