import { useQuery } from '@apollo/react-hooks'
import {
    LIST_PORTFOLIO_SECTIONS,
    SHOW_SLUG,
    SlugType,
    Maybe,
    Slug,
    QueryShowSlugArgs,
    PortfolioSectionConnection,
    QueryListPortfolioSectionsArgs,
} from 'vo-components'

interface PortfolioSectionsSlugQueryArgs {
    slug: string
}

export function usePortfolioSectionsSlugQuery({ slug }: PortfolioSectionsSlugQueryArgs) {
    return useQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>(SHOW_SLUG, {
        variables: { type: SlugType.PortfolioSection, slug },
        skip: !slug,
    })
}

export function usePortfolioSectionsQuery() {
    return useQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>(LIST_PORTFOLIO_SECTIONS, {
        variables: { listing_status: 'ALL' },
    })
}
