import React from 'react'
import { Link } from 'react-router-dom'
import { classes, stylesheet } from 'typestyle'
import { em } from 'csx'
import { Collection } from 'vo-components'
import Media from '../../components/Media'

const styles = stylesheet({
    collectionTitle: {
        marginTop: em(0.5),
    },
})

interface CollectionsProps {
    baseUrl: string
    collections: Collection[]
}

export default function Collections({ baseUrl, collections }: CollectionsProps) {
    return (
        <div className="columns is-multiline">
            {collections.map(collection => (
                <div className="column is-6" key={collection.collection_id}>
                    <Link to={`${baseUrl}/${collection.slug}`}>
                        <figure className="image is-16by9">
                            <Media file={collection.banner || undefined} imageWidth={1280} />
                        </figure>
                    </Link>
                    <h2
                        className={classes('is-size-5 is-size-6-mobile has-text-weight-bold is-uppercase has-letter-spacing', styles.collectionTitle)}
                    >
                        <Link className="has-text-grey-dark" to={`${baseUrl}/${collection.slug}`}>
                            {collection.title}
                        </Link>
                    </h2>
                </div>
            ))}
        </div>
    )
}
