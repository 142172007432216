import { useQuery } from '@apollo/react-hooks'
import { SHOW_PROFILE, Maybe, Profile, QueryShowProfileArgs } from 'vo-components'

interface ProfileQueryArgs {
    pageKey: string
}

export function useProfileQuery({ pageKey }: ProfileQueryArgs) {
    return useQuery<{ showProfile: Maybe<Profile> }, QueryShowProfileArgs>(SHOW_PROFILE, {
        variables: { page_key: pageKey },
    })
}
