import React from 'react'
import { File, DynamicImage, VideoPlayer, getMedia, MediaType, Theme, ImageSize } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import LazyLoad from 'react-lazyload'
import { rgba, scale } from 'csx'

const styles = stylesheet({
    photoContainer: {
        cursor: 'pointer',
        position: 'relative',
        minHeight: 150,
        $nest: {
            '&::before': {
                content: "''",
                zIndex: -1,
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: Theme.colors.greyLight,
                color: 'currentColor',
                textAlign: 'center',
                borderRadius: 2,
                display: 'block',
                width: '100%',
                height: 150,
                overflow: 'hidden',
            },
        },
    },
    zoomableContainer: {
        transition: 'transform 200ms ease-in',
        $nest: {
            '&:hover': {
                transform: scale(1.05),
            },
        },
    },
    playerIconContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        $nest: {
            '&:hover>div': {
                backgroundColor: rgba(0, 0, 0, 0.5).toString(),
            },
        },
    },
    playerIcon: {
        padding: '1em',
        border: '3px solid white',
        borderRadius: '50%',
    },
})

function VideoPlayIcon() {
    return (
        <div className={styles.playerIconContainer}>
            <div className={styles.playerIcon}>
                <span className="icon is-large has-text-white">
                    <i className="fas fa-2x fa-play" aria-hidden="true" />
                </span>
            </div>
        </div>
    )
}

interface MediaProps {
    file?: File
    imageWidth?: ImageSize
    title?: string
    isCover?: boolean
    playing?: boolean
}

export default function Media({ file, title, imageWidth, isCover, playing }: MediaProps) {
    if (!file) return null
    const mediaType = getMedia(file.contentType || '')
    if (mediaType === MediaType.IMAGE) {
        return (
            <div className={classes('has-ratio', styles.photoContainer)}>
                {file.public_url && (
                    <LazyLoad once height={250}>
                        <DynamicImage
                            alt={title}
                            src={file.public_url}
                            width={imageWidth || 1280}
                            srcSet={[
                                { maxScreenWidth: 360, imageWidth: 480 },
                                { maxScreenWidth: 480, imageWidth: 720 },
                            ]}
                        />
                    </LazyLoad>
                )}
            </div>
        )
    }
    if (mediaType === MediaType.VIDEO) {
        return (
            <>
                <div className={classes('has-ratio', !playing && styles.zoomableContainer)}>
                    {file.is_ready && file.public_url && (
                        <VideoPlayer
                            isCover={isCover}
                            playing={playing ?? false}
                            poster={playing ? undefined : file.poster || undefined}
                            url={file.public_url}
                            playIcon={<VideoPlayIcon />}
                        />
                    )}
                </div>
            </>
        )
    }
    return null
}
