import React, { useState } from 'react'
import { classes, media, stylesheet } from 'typestyle'
import { em, rem, important } from 'csx'
import { Theme } from 'vo-components'
import ModalFeedback from './ModalFeedback'

const styles = stylesheet({
    footer: {
        paddingTop: em(1),
        paddingBottom: em(1),
    },
    navigationContainer: {
        ...media(Theme.screen.phone, {
            marginTop: important(rem(0.5)),
        }),
    },
})

function FeedbackButton() {
    const [isModalFeedbackOpen, setIsModalFeedbackOpen] = useState(false)
    return (
        <>
            <ModalFeedback isOpen={isModalFeedbackOpen} onClose={() => setIsModalFeedbackOpen(false)} />
            <button
                onClick={() => setIsModalFeedbackOpen(true)}
                className="has-text-grey-dark level-item button is-text is-paddingless inline-button"
            >
                <u>Give Feedback</u>
            </button>
        </>
    )
}

export default function Footer() {
    const currentYear = new Date().getFullYear()
    return (
        <footer className={classes('footer', styles.footer)}>
            <div className="level">
                <div className="level-left">
                    <div className="level-item content has-text-left">
                        <p>
                            &copy;&nbsp;{currentYear} <strong>Veronica Onggoro</strong>
                        </p>
                    </div>
                </div>
                <div className={classes('level-right', styles.navigationContainer)}>
                    <div className="level-item">
                        <FeedbackButton />
                    </div>
                </div>
            </div>
        </footer>
    )
}
