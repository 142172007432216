import React, { useContext } from 'react'
import { CollectionAsset, LightboxProvider } from 'vo-components'
import Media from '../../components/Media'
import { stylesheet } from 'typestyle'
import { em } from 'csx'

const styles = stylesheet({
    assetContainer: {
        marginBottom: em(1),
    },
})

interface PhotoAssetProps {
    photo: CollectionAsset
    index: number
}

export default function PhotoAsset({ photo, index }: PhotoAssetProps) {
    const { open } = useContext(LightboxProvider)
    return (
        <div className={styles.assetContainer} onClick={() => open && open(index)}>
            <Media title={photo.asset_id} file={photo.file || undefined} imageWidth={1280} />
        </div>
    )
}
