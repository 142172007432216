import React from 'react'
import { media, classes, stylesheet } from 'typestyle'
import { em } from 'csx'
import { useProfileQuery } from '../../graphql/useProfile'
import Loading from '../../components/Loading'
import { Theme, ProfileLatestWorks, Collection } from 'vo-components'
import Media from '../../components/Media'
import { Link } from 'react-router-dom'

const styles = stylesheet({
    headingContainer: {
        marginBottom: em(5),
        ...media(Theme.screen.phone, {
            marginBottom: em(1.25),
        }),
    },
    imageOverlay: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.colors.overlayDark,
    },
})

const PAGE_KEY = 'LATEST_WORKS'

function getUrl(collection: Collection) {
    const baseUrl = collection.portfolio_section ? `/portfolio/${collection.portfolio_section.slug}` : `/projects`
    return `${baseUrl}/${collection.slug}`
}

export default function LatestWork() {
    const { loading, error, data } = useProfileQuery({
        pageKey: PAGE_KEY,
    })
    if (error) return null
    const profile = (data?.showProfile?.value || {}) as ProfileLatestWorks
    const latestWorks = profile.items || []
    return (
        <div className="section">
            <div className="container">
                <div className={styles.headingContainer}>
                    <p className="is-family-secondary has-text-centered is-italic is-lowercase">collection</p>
                    <div>
                        <h2 className="is-size-3-tablet is-size-4-mobile has-text-centered has-text-weight-bold is-uppercase has-letter-spacing">
                            Latest Work
                        </h2>
                    </div>
                </div>
                <div className="columns is-multiline">
                    {loading && (
                        <>
                            {Array(6)
                                .fill(null)
                                .map((_, index) => (
                                    <div key={`loading-bar-latest-work-${index}`} className="column is-4">
                                        <Loading>
                                            <figure className="image is-3by2">
                                                <div className="has-ratio" />
                                            </figure>
                                        </Loading>
                                    </div>
                                ))}
                        </>
                    )}
                    {latestWorks.map(
                        latestWork =>
                            latestWork?.collection && (
                                <div key={`latest-work-${latestWork.collection_id}`} className="column is-4">
                                    <Link to={getUrl(latestWork.collection)}>
                                        <figure className="image is-3by2">
                                            <Media isCover file={latestWork.collection.banner || undefined} imageWidth={720} />

                                            <div className={classes('is-overlay is-flex', styles.imageOverlay)}>
                                                <h5 className="is-size-3 is-uppercase is-size-4-mobile has-text-white title">
                                                    {latestWork.collection.title}
                                                </h5>
                                            </div>
                                        </figure>
                                    </Link>
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}
