import React, { useState } from 'react'
import { keyframes, stylesheet } from 'typestyle'
import { translateX, linearGradient, rgba } from 'csx'
import { useTimeoutFn } from 'react-use'

const skeletalLoadingAnimation = keyframes({
    '100%': { transform: 'translateX(100%)' },
})

const styles = stylesheet({
    loading: {
        position: 'relative',
        backgroundColor: '#E2E2E2',
        overflow: 'hidden',
        $nest: {
            '&::after': {
                display: 'block',
                content: '""',
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                transform: translateX('-100%'),
                background: linearGradient('90deg', 'transparent', rgba(255, 255, 255, 0.6), 'transparent'),
                animation: `${skeletalLoadingAnimation} 1.5s infinite`,
            },
        },
    },
})

interface LoadingProps {
    children: React.ReactNode
}

export default function Loading({ children }: LoadingProps) {
    const [isShown, setIsShown] = useState(false)
    useTimeoutFn(() => setIsShown(true), 300)
    if (!isShown) return null
    return (
        <div className={styles.loading}>
            <div className="is-invisible">{children}</div>
        </div>
    )
}
