import React from 'react'
import { LottieBanana } from './Lottie'

interface ErrorProps {
    retry?: () => void
    isCompact?: boolean
}

export default function Error({ retry, isCompact }: ErrorProps) {
    return (
        <div className="container has-text-centered">
            {isCompact ? (
                <p className="is-size-6 is-subtitle">Something went wrong!</p>
            ) : (
                <>
                    <LottieBanana height={320} />
                    <h1 className="is-size-1 is-title">Uh oh...</h1>
                    <p className="is-size-6 is-subtitle">Something went wrong!</p>
                </>
            )}

            {retry && (
                <button
                    style={{ marginTop: 20 }}
                    className="button is-info"
                    onClick={() => {
                        retry()
                    }}
                >
                    Retry
                </button>
            )}
        </div>
    )
}
