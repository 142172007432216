import React from 'react'
import LandingBanner from './LandingBanner'
import LandingSectionLinks from './LandingSectionLinks'
import { media, classes, stylesheet } from 'typestyle'
import { Theme } from 'vo-components'

const styles = stylesheet({
    columns: {
        ...media(Theme.screen.phone, {
            flexDirection: 'column-reverse',
        }),
    },
})

export default function Landing() {
    return (
        <div className="section">
            <div className="container">
                <div className={classes('columns is-mobile', styles.columns)}>
                    <div className="column is-one-third-tablet is-narrow is-full-mobile">
                        <LandingSectionLinks />
                    </div>
                    <div className="column is-full-mobile">
                        <LandingBanner />
                    </div>
                </div>
            </div>
        </div>
    )
}
