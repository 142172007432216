import React from 'react'
import Landing from './Landing'
import LatestWork from './LatestWork'
import AboutMe from './AboutMe'
import { media, stylesheet } from 'typestyle'
import { em } from 'csx'
import { Theme } from 'vo-components'
import { Helmet } from 'react-helmet'

const styles = stylesheet({
    section: {
        paddingTop: em(5),
        marginTop: em(5),
        paddingBottom: em(5),
        marginBottom: em(5),
        backgroundColor: Theme.colors.whiteBis,
        ...media(Theme.screen.phone, {
            paddingTop: em(1.25),
            marginTop: em(1.25),
            paddingBottom: em(1.25),
            marginBottom: em(1.25),
        }),
    },
})

export default function PageHome() {
    return (
        <>
            <Helmet>
                <title>Veronica Onggoro - Melbourne Photographer</title>
                <meta property="og:url" content="https://veronicaonggoro.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Veronica Onggoro" />
                <meta
                    name="description"
                    content="Veronica is a professional photographer based in Melbourne. She specialises in family, wedding, event, and portrait photography."
                />
            </Helmet>
            <Landing />
            <div className={styles.section}>
                <AboutMe />
            </div>
            <LatestWork />
        </>
    )
}
