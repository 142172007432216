import { useMutation } from '@apollo/react-hooks'
import { SEND_MESSAGE, Mutation, MutationCreateMessageArgs } from 'vo-components'

interface SendMessageMutationArgs {
    listingStatus: string
    reply_to: string
    name: string
    message: string
    onCompleted?: () => void
}

export function useSendMessageMutation({ listingStatus, reply_to, name, message, onCompleted }: SendMessageMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCreateMessageArgs>(SEND_MESSAGE, {
        onCompleted,
        variables: {
            input: {
                listing_status: listingStatus,
                name,
                reply_to,
                message,
            },
        },
    })
}

interface SendWebsiteFeedbackMutationArgs {
    type?: string
    message?: string
    onCompleted?: () => void
}

export function useSendWebsiteFeedbackMutation({ type, message, onCompleted }: SendWebsiteFeedbackMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCreateMessageArgs>(SEND_MESSAGE, {
        onCompleted,
        variables: {
            input: {
                listing_status: 'WEBSITE_FEEDBACK',
                type,
                message,
            },
        },
    })
}
